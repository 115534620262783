/* All Mobile Devices */
@media only screen and (max-width: 599px) {
  .container,
  .auth-container,
  .header-view-status-container {
    float: none;
    margin: 0 16px;
    padding: 0;
    width: calc(100% - 32px);
  }

  .auth-logo {
    width: calc(100%);
  }

  .auth-form-container,
  .auth-form-content.login,
  .auth-form-content.register,
  .auth-image-content.login,
  .auth-image-content.register {
    float: none;
    margin: 0 auto;
    width: 100%;
  }

  .half-half-columns {
    margin-top: 48px;
    grid-template-columns: 100% !important;
  }

  .input-box .label,
  .two-fields label,
  .select-box-main .label {
    margin-bottom: 8px;
    width: 100%;
  }

  .form-layout {
    flex-direction: column;
    margin-top: 44px;
  }

  .form-section-title {
    font: normal normal 700 18px 'Inter' !important;
    line-height: 150% !important;
    margin: 0px 0px 24px 0px;
    margin-top: 8px;
    width: 80%;
  }

  .form-content {
    margin: 10px 0px 0px 0px;
    width: 100%;
  }

  .button-group {
    grid-row-gap: 10px;
    justify-content: normal !important;
  }

  .back-next-container {
    position: fixed;
    bottom: 0;
    left: 0;
    animation: 500ms ease-in-out 0s normal none 1 running fadeInDown;
    background: #fff;
    box-shadow: 0 2px 24px 0 rgb(0 0 0 / 15%);
    padding-bottom: 0px;
    padding-top: 0px;
    width: 100%;
  }

  .button-group .back-button {
    background: var(--background-color-light);
  }

  .empty-div {
    display: none;
  }

  .button-group.back-next button:first-of-type {
    margin-top: 0px;
  }

  .button-group.full {
    grid-row-gap: 0px;
  }

  .button-group.full .register {
    margin-bottom: 10px;
  }

  .button-group.back-next button:nth-child(2) {
    margin-top: 10px;
  }

  .back-next button,
  .back-next.medium button {
    align-self: center;
    border-radius: 0;
    height: 64px;
    width: 100%;
  }

  .back-next.full button {
    align-self: center !important;
    border-radius: 4px;
    height: 40px !important;
    width: 98%;
  }

  .button-mobile {
    position: fixed;
    bottom: 0;
    left: 0;
    border-radius: 0 !important;
    padding: 31px;
    width: 100% !important;
    z-index: 200;
  }

  .button-group .back-button {
    align-items: center;
    align-self: flex-start;
    margin-left: 5px;
  }

  .button-group.back-next {
    align-self: center;
    width: 100%;
  }

  .button-group .back-button svg {
    margin-right: 2px;
  }

  .car-selector {
    display: grid;
    grid-template-columns: 100%;
    grid-template-rows: 30% 40% 40%;
  }

  .car-selector-content {
    display: grid;
    flex-direction: column;
    width: 100%;
  }

  .car-selector-content .content-title {
    display: grid;
    grid-template-columns: 60% 50%;
    line-height: 150% !important;
    padding: 0;
  }

  .car-selector-content .title .price {
    display: block;
    color: #4ea699;
    justify-self: center;
  }

  .car-selector-content .price-desktop {
    display: none;
  }

  .mobile-hidden {
    display: none !important;
  }

  .back-button {
    margin-left: 0px !important;
    margin-bottom: 16px;
  }

  .choice-selection {
    grid-template-columns: 100%;
  }

  .checkmark {
    height: 20px;
    width: 20px;
    align-self: baseline;
  }

  .checkbox .checkmark:after {
    left: 5.35px;
    top: 1.75px;
    width: 4px;
    height: 10px;
    border-width: 0 1px 1px 0;
  }

  .checkbox {
    margin-right: 30px;
  }

  .checkmark.round {
    left: 0px;
  }

  .layout {
    margin-top: 16px !important;
  }

  .collapsible-arrow svg {
    width: 10px;
    height: 8px;
  }

  .collapsible-item {
    grid-template-columns: 40% 60%;
    align-items: center;
    align-content: center;
  }

  .collapsible-container .border-bottom {
    margin-bottom: 24px;
  }

  .collapsible-action {
    align-items: center;
  }

  .intl-countries-list {
    height: 300px;
    margin: 0 auto;
    font: normal normal 300 15px 'Inter' !important;
    line-height: 160% !important;
    padding: 15px 10px;
    width: calc(70% + 110px);
    margin-left: auto;
  }

  .intl-country {
    height: 48px;
    display: flex;
    align-items: center;
  }

  .modal-container.middle {
    left: 0;
    top: 0;
    width: calc(100% - 32px);
    padding: 24px 16px;
  }

  .upload-input-mobile {
    display: block;
  }

  .auth-image-container {
    display: none;
  }

  .interactive-choices {
    grid-template-columns: 100% !important;
    row-gap: 16px;
  }

  button.long {
    width: 400px;
  }

  .contracts-history .header-view-status-container {
    margin-bottom: 0;
  }

  .contracts-history .contracts-container {
    margin-top: 25px;
  }

  .contracts {
    grid-template-columns: 100%;
  }

  .contract-download .contract {
    grid-template-columns: 100% !important;
  }

  .contract-download .contract div:nth-child(1) {
    grid-template-columns: 10% 85%;
    gap: 8%;
  }

  .contract-download .contract div:nth-child(2) {
    grid-template-columns: 20% 80%;
  }

  .contracts .interactive-choice {
    height: 50px;
    margin-top: 0;
  }

  .contract .checkbox {
    margin-right: 0;
  }

  .contracts-history .content-title {
    font-size: 10px !important;
  }

  .upload-box-actions-mobile {
    width: 100%;
    margin: 48px 0 16px 0;
  }

  .upload-box-details-delete-icon {
    display: none;
  }

  .upload-box-details-delete-icon-white {
    display: block;
  }

  .delete {
    display: none;
  }

  .upload-box {
    height: 64px;
    border-radius: 4px;
    transition: all 0.7s ease;
    grid-template-columns: auto 64px;
    align-content: normal;
  }

  .upload-box-details {
    margin-left: 10px;
    grid-template-columns: 15% 75%;
  }

  .upload-box-details svg {
    width: 30px;
  }

  .upload-box-actions {
    height: 100%;
    background-color: #ef476f;
    width: 100%;
    margin: 0;
  }

  .upload-box-actions.green {
    background-color: var(--secondary-color);
  }

  .upload-box-details-delete-icon {
    display: none;
  }

  .upload-box-details-delete-icon-white {
    display: block;
  }

  .delete {
    display: none;
  }

  .upload-box {
    height: 64px;
    border-radius: 4px;
    transition: all 0.7s ease;
    grid-template-columns: auto 64px;
    align-content: normal;
  }

  .upload-box-actions {
    grid-template-columns: 40% 50%;
  }

  .upload-box-details {
    margin-left: 10px;
    grid-template-columns: 15% 75%;
  }

  .upload-box-details svg {
    width: 30px;
  }

  .two-fields {
    display: flex;
    flex-direction: column;
  }

  .input-box {
    flex-direction: column;
  }

  .input-box.row-input-field .label label {
    max-width: auto;
    width: auto;
  }

  .error-icon-new {
    top: 14px;
  }

  .additional-element {
    top: 17px;
  }

  .subheader {
    padding-bottom: 20px;
    margin-bottom: 0px !important;
  }

  .subheader-body .arrow-down {
    position: relative;
    left: 50%;
    top: 0%;
  }

  .subheader-body-content {
    grid-template-columns: 100%;
    left: 0;
    gap: 0;
  }

  .subheader-body-content.visible {
    height: auto;
    margin: 0;
    padding: 0 16px 0 16px;
  }

  .sequence-steps {
    flex-direction: column;
  }

  .status-name-desc {
    margin-top: 0px;
    text-align: left;
    height: auto;
    align-items: center;
    margin-bottom: 16px;
  }

  .status-name-desc h2 {
    margin-bottom: 0px !important;
  }

  .single-step:nth-child(1) {
    scroll-margin-bottom: -100px;
  }

  .single-step {
    scroll-margin-bottom: 150px;
    min-width: 85%;
    margin: 0 24px 250px 24px;
  }

  .select-box-main {
    flex-direction: column;
  }

  .select-box input {
    width: 85%;
  }

  .options {
    width: calc(100% - 1px);
  }

  .confirm-element-section {
    justify-content: flex-start;
  }

  .confirm-section {
    display: flex;
    flex-direction: column;
    padding: 16px 0;
    gap: 0;
  }

  .confirm-only-mobile {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: baseline;
    margin-bottom: 12px;
  }

  .confirm-element {
    grid-template-columns: auto auto;
  }

  .confirm-element .confirm-value {
    margin-left: 0;
  }

  .confirm-element h4,
  .confirm-element p {
    max-width: 150px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .confirm-value {
    width: 350px;
    margin-left: 48px;
  }

  .search-result-content {
    margin-top: 24px;
  }

  .result {
    margin-top: 24px;
    display: grid;
    align-items: center;
    grid-template-columns: 100%;
    row-gap: 24px;
  }

  .result .content-small {
    margin-bottom: 8px;
    display: block;
  }

  .result .result-title {
    display: block;
    margin: 8px 0;
  }

  .result button.full:first-of-type {
    margin-top: 24px;
  }

  .upload-box,
  .checkbox-insurance-box {
    margin-left: 0px;
  }

  .IDNow-overlay div {
    width: 350px;
    left: calc(50% - 175px);
  }

  .idnow-info-details {
    display: grid;
    grid-template-columns: 100%;
    row-gap: 15px;
    padding: 0;
  }

  .auth-form-container.login div h1 {
    margin: 48px 0;
  }

  .checkbox-slide .checkbox-content {
    min-height: 100px;
  }

  .checkbox-content span,
  .checkbox-content p {
    margin-top: 0;
    display: block;
  }

  .subheader .left .heading {
    grid-template-columns: auto auto;
    gap: 12px;
  }

  .pill.leasing {
    font-size: 8px !important;
    line-height: 18px !important;
    margin: 0;
    padding: 0 4px;
    max-height: 17px;
  }

  .header .logo {
    margin-top: 0 !important;
  }

  .logo {
    margin-top: 16px !important;
  }

  button .content-small-bold {
    font-size: 15px !important;
  }

  .sequence-container {
    margin-top: 48px;
  }

  .sequence-icon {
    width: 209.88px;
    height: 176.62px;
  }

  .sequence-number {
    margin: 0 -5px 0 0;
    font: normal normal 700 160px/193.64px 'Inter';
  }

  .header-right-menu {
    grid-template-columns: auto auto auto;
  }

  .menu-header {
    border: #fff;
    height: calc(100vh - 70px);
    left: 0 !important;
    max-height: calc(100vh - 120px);
    padding-bottom: 49px;
    position: fixed !important;
    right: 0;
    top: 55px !important;
    width: calc(99vw - 32px);
    z-index: 1000;
  }

  .menu-header-body .header-section {
    max-width: 600px;
  }

  .title.no-offer {
    margin-top: 200px !important;
  }

  .loader-container svg {
    width: 340px;
  }

  .loader {
    grid-template-columns: 5% 5% 5% 5% 5%;
  }

  .forgot-link {
    margin-top: 8px;
  }

  .title.price {
    font: normal normal 700 19px/22.8px 'Inter' !important;
    letter-spacing: 0;
  }

  .subheader-body.visible {
    padding-top: 20px;
    padding-bottom: 24px;
    margin-bottom: 0px;
  }

  .lease-info {
    margin-top: 24px;
  }

  .lease-info h3,
  .lease-info p {
    margin-bottom: 16px;
  }

  .lease-info.last {
    margin-bottom: 24px;
  }

  .car-details-image {
    height: auto;
    min-height: 210px;
  }

  .logout-section svg {
    margin-top: -3px;
  }

  .logout-section {
    border-radius: 4px !important;
    margin-top: 16px;
  }

  .bootstrap .car-details {
    grid-template-columns: 100%;
  }

  .bootstrap .car-visual {
    margin-bottom: 48px;
  }

  .idnow-checkbox {
    padding-bottom: 48px;
  }

  .footer {
    margin-bottom: 48px;
  }

  .input-with-icon-container {
    grid-template-columns: 30% 70%;
  }

  .input-icon:after {
    padding-left: calc(30% - 10px);
  }

  .checkbox-slide.active.shown p {
    max-width: 310px;
  }

  .progress {
    width: 100%;
    margin: -7px 0 24px 0;
  }

  .progress-item:first-of-type {
    margin-left: 0px;
  }

  .document-layout {
    margin: 48px 0 24px 0;
  }

  .document-layout p {
    margin-top: 12px;
  }

  .upload-box-actions-mobile svg {
    margin-right: 8px;
  }

  .upload-box-details-mobile .content-normal.dark-grey {
    margin-bottom: 32px;
  }

  .upload-container .upload-error {
    margin-left: 0px;
  }

  .credit-check-title {
    margin-top: 48px;
    margin-bottom: 8px;
  }

  h1 {
    margin-bottom: 12px;
  }

  h2,
  h3,
  h4,
  h5 {
    margin-bottom: 16px !important;
  }

  .pricing-details {
    grid-template-columns: 50% 50%;
  }

  .interactive-choice:nth-child(1) {
    margin-bottom: 0px;
  }

  .input-margin {
    margin: 20px 0;
  }

  .progress-item .progress-tooltip {
    display: none;
  }

  .tabs-container {
    margin-left: -10px;
    padding-left: 15px;
    width: 350px;
    -webkit-mask-image: linear-gradient(
      to right,
      rgba(0, 0, 0, 0) 0%,
      rgba(0, 0, 0, 1) 20%,
      rgba(0, 0, 0, 1) 80%,
      rgba(0, 0, 0, 0) 100%
    );
    mask-image: linear-gradient(
      to right,
      rgba(0, 0, 0, 0) 0%,
      rgba(0, 0, 0, 1) 5%,
      rgba(0, 0, 0, 1) 80%,
      rgba(0, 0, 0, 0) 100%
    );
    overflow-x: auto;
    white-space: nowrap;
    scrollbar-width: none;
    -ms-overflow-style: none;
  }

  .subheader-body-content .cancel-application-text {
    position: relative;
  }

  .header-view-status-container {
    position: fixed;
    margin: 0 16px !important;
    background: var(--background-color);
  }

  .header-view-status-container.active {
    padding-left: 0 !important;
    position: initial;
  }

  .header-view-status-container ~ main {
    padding-top: 164px;
  }

  .header-view-status-container.x ~ main {
    padding-top: 56px;
  }

  .idnow-info .heading {
    display: block;
  }

  .idnow-info .heading svg {
    width: 60px;
    height: 15px;
  }

  .idnow-info-steps {
    max-width: 300px;
  }

  .animation-layout {
    max-width: 360px;
  }

  .credit-check-loader-animate:nth-child(1) {
    width: 330px;
  }

  .credit-check-loader-animate:nth-child(2) {
    top: 45%;
    width: 120px;
  }

  .credit-check-loader-animate:nth-child(3) {
    top: 28%;
    width: 80px;
  }

  .credit-check-loader-animate:nth-child(4) {
    top: 37%;
    width: 148px;
  }

  .credit-check-loader-animate:nth-child(5) {
    width: 128px;
  }

  .credit-check-loader-animate:nth-child(6) {
    top: 37%;
    left: 20%;
    height: 87px;
  }

  .animate:nth-child(5) {
    z-index: 0;
    width: 360px;
  }

  .animate {
    left: 0%;
  }

  .cancel-contract {
    width: 358px;
  }

  .arrow-list-icon {
    height: 20px;
    width: 20px;
  }

  .animate:nth-child(1) {
    left: 47%;
  }

  .credit-check {
    width: 330px;
  }

  .modal.animated,
  .loader-container.credit,
  .loader-container.credit-out,
  .loader-container.credit .title,
  .loader-container.credit .loader {
    animation: none;
    -moz-animation: none;
    -webkit-animation: none;
    -o-animation: none;
  }

  .loader-container.credit-out,
  .loader-container.credit {
    margin-top: -80px;
    margin-left: 0;
  }

  @keyframes slideInCheckBox {
    from {
      height: 0px;
    }
    to {
      height: 200px;
    }
  }
}

/* All Tablet Devices */
@media only screen and (min-width: 600px) and (max-width: 1439px) {
  .container,
  .auth-container,
  .auth-form-container,
  .auth-image-content.login,
  .auth-image-content.register,
  .auth-form-content,
  .header-view-status-container {
    float: none;
    margin: 0 auto;
    padding: 0;
    width: calc(100% - 300px);
  }

  .contract-download .contract {
    grid-template-columns: 85% 35%;
  }

  .auth-form-content.login,
  .auth-form-content.register {
    margin: 0 auto;
    width: 100%;
  }

  .auth-logo {
    width: calc(100% - 16px);
  }

  .pill {
    font-size: 14px;
    border-radius: 2px;
    line-height: 25px;
  }

  .back-button {
    margin-bottom: 40px;
  }

  .half-half-columns {
    display: grid;
    grid-template-columns: 100%;
  }

  .half-half-columns svg,
  .half-half-columns img {
    margin: auto;
    max-width: 300px;
  }

  .button-group.back-next {
    display: flex;
    flex-direction: column;
  }

  .grid-template {
    display: block;
  }

  .layout {
    min-height: 90vh;
  }

  .button-group.default {
    grid-template-columns: 48% 48%;
    gap: 24px;
  }

  .car-selector {
    background: rgba(255, 255, 255, 0.94);
    border-radius: 16px;
    border-top: 0px;
    box-shadow: 0px 15px 30px rgba(171, 170, 170, 59%);
    flex-direction: row-reverse;
    max-width: 704px;
    padding: 50px;
    padding-right: 30px;
  }

  .car-selector .select-car-button {
    display: none;
  }

  .mobile-only {
    display: none !important;
  }

  .auth-image-container.login {
    display: none;
  }

  .subheader-body-content {
    grid-template-columns: 100%;
  }

  .sequence-steps {
    margin-top: 72px;
    display: grid;
    grid-template-columns: 50% 50%;
  }

  .single-step {
    width: 350px;
  }

  .status-name-desc {
    margin-top: 24px;
  }

  .input-margin {
    display: grid;
    grid-template-columns: 33.2% 67.8%;
  }

  h1,
  h2,
  h3,
  h4,
  h5 {
    margin-bottom: 8px !important;
  }

  .result {
    grid-template-columns: 47.5% 47.5%;
    column-gap: 5%;
  }

  .contract-download .contract.small {
    grid-template-columns: 70% 35%;
  }

  .contract-download .contract.small div:nth-child(1) {
    grid-template-columns: 25% 80%;
  }

  .subheader-body-content .cancel-application-text {
    position: relative;
  }

  .sequence-container {
    height: 140px;
  }
}

@media screen and (min-width: 900px) and (max-width: 1439px) {
  .subheader-body-content {
    grid-template-columns: 50% 50%;
  }
}

/* Small Mobile Devices */
@media screen and (max-width: 360px) {
  .container,
  .auth-container,
  .auth-form-container,
  .auth-form-content.login,
  .auth-form-content.register,
  .auth-image-content.login,
  .auth-image-content.register,
  .header-view-status-container {
    margin: 0 auto;
    padding: 0;
    width: 328px;
  }

  .grid-template {
    display: grid;
    align-items: center;
    gap: 20px;
    height: auto;
    min-height: calc(100vh - 200px);
  }

  .layout {
    min-height: calc(100vh - 70px);
  }

  button.long {
    width: 290px;
  }

  .IDNow-overlay div {
    width: 300px;
    left: calc(50% - 150px);
  }

  .auth-logo {
    width: 327px;
  }

  .loader-container svg {
    width: 320px;
  }

  .loader {
    grid-template-columns: 5% 5% 5% 5% 5%;
  }
}

/* Middle Mobile Devices */
@media screen and (min-width: 361px) and (max-width: 390px) {
  .container,
  .auth-container,
  .auth-form-container,
  .auth-form-content.login,
  .auth-form-content.register,
  .auth-image-content.login,
  .auth-image-content.register,
  .header-view-status-container {
    margin: 0 auto;
    padding: 0;
    width: 358px;
  }

  .grid-template {
    display: grid;
    align-items: center;
    height: auto;
    min-height: calc(100vh - 250px);
  }

  button.long {
    width: 330px;
  }

  .auth-logo {
    width: 357px;
  }

  .loader-container svg {
    width: 360px;
  }

  .loader {
    grid-template-columns: 5% 5% 5% 5% 5%;
  }
}

/* Large Mobile Devices */
@media screen and (min-width: 391px) and (max-width: 393px) {
  .container,
  .auth-container,
  .auth-form-container,
  .auth-form-content.login,
  .auth-form-content.register,
  .auth-image-content.login,
  .auth-image-content.register,
  .header-view-status-container {
    margin: 0 auto;
    padding: 0;
    width: 361px;
  }

  .grid-template {
    display: grid;
    align-items: center;
    height: auto;
    min-height: calc(100vh - 250px);
  }

  button.long {
    width: 360px;
  }

  .auth-logo {
    width: 360px;
  }

  .loader-container svg {
    width: 363px;
  }

  .loader {
    grid-template-columns: 5% 5% 5% 5% 5%;
  }
}

/* Large Screen Devices */
@media only screen and (min-width: 1440px) {
  .container,
  .subheader,
  .header-view-status-container {
    margin: 0 auto;
    width: 1024px;
  }

  .header-view-status-container {
    position: fixed;
    background: white;
    width: 100%;
  }

  .header-view-status-container.active {
    position: relative;
  }

  .header {
    margin: 16px auto 0 auto;
    width: 1024px;
  }

  .inner-container {
    margin: auto;
    width: 678px;
  }

  .button-choice {
    border-radius: 8px;
    height: 48px;
    padding: 16px 20px 16px 20px;
    width: 157px;
  }

  .button-group.back-next {
    display: flex;
    align-self: baseline;
    flex-direction: column;
  }

  .grid-template {
    display: grid;
    align-items: center;
    height: auto;
    margin-top: 96px;
  }

  .bootstrap.grid-template {
    margin-top: 0px;
  }

  .input-margin {
    display: grid;
    grid-template-columns: 33.2% 67.8%;
  }

  .layout {
    min-height: calc(100vh - 100px);
  }

  .button-group.default {
    grid-template-columns: 48% 48%;
    gap: 24px;
  }

  .interactive-choice .checkbox {
    margin-bottom: 2px;
  }

  .mobile-only {
    display: none !important;
  }

  .header-view-status-container ~ main {
    padding-top: 138px;
  }

  .header-view-status-container.x ~ main {
    padding-top: 0px;
  }

  .sequence-steps {
    margin-top: 72px;
  }

  .sequence-container {
    height: 100px;
  }

  .loader-container.credit-out,
  .loader-container.credit-out {
    padding-top: 300px;
  }
}

/* Small Laptop Macbook 14 Pro */
@media only screen and (min-height: 700px) and (max-height: 733px) {
  .loader-container.credit,
  .loader-container.credit-out {
    padding-top: 100px;
    margin-left: 0;
  }

  .modal.animated,
  .loader-container.credit,
  .loader-container.credit-out,
  .loader-container.credit .title,
  .loader-container.credit .loader {
    animation: none;
    -moz-animation: none;
    -webkit-animation: none;
    -o-animation: none;
  }
}
